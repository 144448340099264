import { ProjectType } from '~/types/project'

export const colors = {
  blue: {
    100: '#99BCFF',
    300: '#4A88FF',
    500: '#003499',
  },
  teal: {
    100: '#99D2D5',
    300: '#00A1A8',
    500: '#006B70',
  },
  green: {
    100: '#F0FFB2',
    300: '#8FB200',
    500: '#5E7500',
  },
  pink: {
    100: '#FF99C0',
    300: '#FF1F74',
    500: '#B20044',
  },
  ['brand-green']: {
    50: '#F5F6F3',
    100: '#DFE3D8',
    200: '#B2BDA3',
    300: '#85976D',
    400: '#566246',
    500: '#272D20',
  },
  // opacity variants of brand-green-100 for hover / selected states
  ['brand-green-100-lighter']: {
    30: '#EEF0EB',
    60: '#E7E9E1',
  },
  ['brand-blue']: {
    50: '#E0F2FF',
    100: '#B2DFFF',
    200: '#57B8FF',
    300: '#0090F8',
    400: '#005C9E',
    450: '#004170',
    500: '#002642',
  },
  orange: {
    100: '#FFDD99',
    300: '#FFAC05',
  },
  grey: {
    100: '#F0F0F0',
    200: '#D9D9D9',
    300: '#949494',
    400: '#4F4F4F',
    500: '#212121',
  },
  ['brand-neon']: {
    flame: '#FF5C29',
    spark: '#C3FF44',
  },

  white: '#FCFCFC',
  black: '#141515',
  current: 'currentColor',
  transparent: 'transparent',

  success: {
    100: '#E5F5EE',
    400: '#009C51',
    500: '#076D3C',
  },
  warning: {
    100: '#FAF0D6',
    400: '#EABF4F',
    500: '#9F7813',
  },
  error: {
    100: '#F7D9D4',
    400: '#DA4A31',
    500: '#822717',
  },
}

export const projectTypeColors = {
  AFFORESTATION_REFORESTATION_REVEGETATION: {
    bg: colors.green[100],
    text: 'black',
  },
  CONSERVATION_RESTORATION_REDD_PLUS: { bg: colors.green[500], text: 'white' },
  COOKSTOVES: { bg: colors.pink[500], text: 'white' },
  IMPROVED_FOREST_MANAGEMENT: { bg: colors.green[300], text: 'black' },
  SOIL_CARBON: { bg: colors.orange[100], text: 'black' },
  BIOCHAR: { bg: colors.orange[300], text: 'black' },
  WETLAND_PEATLAND_RESTORATION: { bg: colors.teal[300], text: 'black' },
  BLUE_CARBON: { bg: colors.blue[500], text: 'white' },
  ENHANCED_WEATHERING: { bg: colors.blue[300], text: 'black' },
  CARBON_CAPTURE_STORAGE: { bg: colors.teal[500], text: 'white' },
  WOODEN_BUILDING_ELEMENTS: { bg: colors.pink[300], text: 'black' },
  CO2_UTILISATION: { bg: '#9F833B', text: 'black' },
  INDUSTRIAL_EFFICIENCY: { bg: colors.pink[100], text: 'black' },
  DIRECT_AIR_CAPTURE: { bg: colors.teal[100], text: 'black' },
  RENEWABLE_ENERGY: { bg: colors.blue[100], text: 'black' },
  OTHER: { bg: '#ABABAB', text: 'black' },
} satisfies Record<
  ProjectType,
  {
    bg: string
    text: 'black' | 'white'
  }
>
